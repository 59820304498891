'use strict';
(function () {

    class ReportConfigComponent {
        constructor($scope, $state, Turbine, Site, Blade, usSpinnerService, toastr, Util, Auth, Report) {
            this.Util = Util;
            this.Blade = Blade;
            this.Site = Site;
            this.Turbine = Turbine;
            this.Report = Report;
            this.toastr = toastr;
            this.usSpinnerService = usSpinnerService;
            this.isDisabled = false;
            this.errors = {};
            this.submitted = false;
            this.Auth = Auth;
            this.isSuccess = false;
            this.bladeData = [];
            this.$state = $state;
            this.reportDateRange = { date: { startDate: null, endDate: null } };

            Auth.isLoggedInAsync((loggedIn) => {
                if (loggedIn) {
                    this.init();
                    this.currentUser = Auth.getCurrentUser();
                } else {
                    $state.go('login');
                }
            });

            this.selectedType = 'Finding';
            this.generatePdfForEachTurbine = false;

        }

        init() {
            // console.log(this.Auth.getCurrentUser().organization._id);
            this.Site.get({ populateBlade: true }).$promise
                .then(response => {
                    if (response.data && response.data.length) {
                        this.sites = response.data.sort((a,b) => a.name.localeCompare(b.name));
                        this.loading = false;
                        this.stopSpin('spinner-2');
                        // console.log(this.sites);
                    }
                })
                .catch(err => {
                    console.log(err);
                    this.loading = false;
                    this.stopSpin('spinner-2');
                })
        }

        startSpin(spinner) {
            this.usSpinnerService.spin(spinner);
        }

        stopSpin(spinner) {
            this.usSpinnerService.stop(spinner);
        }

        reportData(form) {
            if (!this.isDisabled) {
                this.submitted = true;
                this.serverError = null;
                if (form.$valid) {
                    this.isDisabled = true;
                    this.startSpin('spinner-2');

                    var mappedTurbines = this.generatePdfForEachTurbine ? [] : _.map(this.turbines, _.partialRight(_.pick, ['_id']));
                    var mappedBlades = this.generatePdfForEachTurbine ? [] :_.map(this.blades, _.partialRight(_.pick, ['_id']));
                    var mappedFindings = this.generatePdfForEachTurbine ? [] :_.map(this.selectedFindings, _.partialRight(_.pick, ['_id']));
                    var mappedRepairs = this.generatePdfForEachTurbine ? [] :_.map(this.selectedRepairs, _.partialRight(_.pick, ['_id']));

                    const addReportData = {
                        _id: this.site._id,
                        site: this.site,
                        turbines: mappedTurbines,
                        blades: mappedBlades,
                        findings: mappedFindings,
                        repairs: mappedRepairs,
                        type: this.selectedType,
                        comment: this.comments,
                        reportNumber: Date.now(),
                        createdBy: this.currentUser._id,
                        generatePdfForEachTurbine: this.generatePdfForEachTurbine
                    }

                    if(this.reportDateRange.date.startDate != null) {
                        addReportData.startDate = this.reportDateRange.date.startDate;
                    }
                    if(this.reportDateRange.date.endDate != null) {
                        addReportData.endDate = this.reportDateRange.date.endDate;
                    }

                    this.Report.addReport(addReportData).$promise
                        .then(report => {
                            this.stopSpin('spinner-2');
                            console.log(report);
                            this.isSuccess = true;
                            // this.isDisabled = false;
                            // this.$state.reload();
                        })
                        .catch(err => {
                            this.isDisabled = false;
                            this.errors = {};
                            this.stopSpin('spinner-2');
                            this.handleError(err, form);
                        });
                }
            }
        }

        resetFrom() {
            this.submitted = false;
            this.$state.reload();
        }

        setBlades() {
            this.bladeData = [];
            if (this.turbines.length) {
                this.turbines.forEach(turbine => {
                    // console.log(turbine.name);
                    var PushBladesData = turbine.blades.map(blade => {
                        blade.position = (turbine.name || '') + ' ' + (turbine.model || '') + ' (' + turbine.serial + ') - ' + blade.position;
                        // console.log(blade.position);
                        return blade;
                    });
                    this.bladeData.push(PushBladesData);
                });
                this.bladeData = _.flatten(this.bladeData);

                this.bladeData.forEach(blade => {
                    let displayBlade = '';
                    if(blade.position) {
                        displayBlade+=blade.position;
                    }
                    if(blade.model) {
                        displayBlade+= ' ('+blade.model+')';
                    }
                    blade.displayBlade = displayBlade.trim();
                })
    
                this.bladeData = _.sortBy(this.bladeData, (blade) => {
                    return [blade.displayBlade.toLowerCase()];
                });
            }
            // console.log(this.bladeData);
        }

        setFindingsOrRepairs() {
            if (this.selectedType == 'Finding') {
                this.setFindings();
            } else {
                this.setRepairs();
            }
        }

        setFindings() {
            if(this.generatePdfForEachTurbine) return;
            if (this.blades.length) {
                this.startSpin('spinner-2'); 
                let bladeIds =  _.map(this.blades, '_id');
                console.log(bladeIds);
                this.Report.getForBladeFindings({ id: this.site._id }, { bladeIds: bladeIds }).$promise
                .then(response => {
                    this.stopSpin('spinner-2');
                    this.findingData = response.data;
                    this.selectedFindings = this.findingData;
                    this.findingData.forEach(finding => {
                        let displayFinding = '';
                        if(finding.date || finding.createdAt) {
                            displayFinding+=moment(finding.date).format('DD/MM/YYYY') || moment(finding.createdAt).format('DD/MM/YYYY');
                        }
                        if(finding.uniqueReferenceNumber) {
                            displayFinding+= ' | '+finding.uniqueReferenceNumber;
                        }
                        finding.displayFinding = displayFinding.trim();
                    })
                    this.findingData = _.sortBy(this.findingData, (finding) => {
                        return [finding.displayFinding.toLowerCase()];
                    });
                })
                .catch(err => {
                    this.findingData = [];
                    this.stopSpin('spinner-2');
                    console.log(err);
                });
            }
        }

        setRepairs() {
            if(this.generatePdfForEachTurbine) return;
            if (this.blades.length) {
                this.startSpin('spinner-2');
                let bladeIds =  _.map(this.blades, '_id');
                console.log(bladeIds);
                this.Report.getForBladeRepairs({ id: this.site._id }, { bladeIds: bladeIds }).$promise
                .then(response => {
                    this.stopSpin('spinner-2');
                    this.repairData = response.data;
                    this.selectedRepairs = this.repairData;
                    this.repairData.forEach(repair => {
                        let displayRepair = '';
                        if(repair.date || repair.createdAt) {
                            displayRepair+=moment(repair.date).format('DD/MM/YYYY') || moment(repair.createdAt).format('DD/MM/YYYY');
                        }
                        if(repair.uniqueReferenceNumber) {
                            displayRepair+= ' | '+repair.uniqueReferenceNumber;
                        }
                        if(repair.woId) {
                            displayRepair+= ' | '+repair.woId;
                        }
                        repair.displayRepair = displayRepair.trim();
                    })
                    this.repairData = _.sortBy(this.repairData, (repair) => {
                        return [repair.displayRepair.toLowerCase()];
                    });
                })
                .catch(err => {
                    this.repairData = [];
                    this.stopSpin('spinner-2');
                    console.log(err);
                });
            }
        }

        handleError(err, form) {
            if (err && err.data && err.data.meta && err.data.meta.error_message) {
                if (err.data.meta.code && err.data.meta.code != 422 && !this.Util.isArray(err.data.meta.error_message)) {
                    this.serverError = err.data.meta.error_message || 'Internal Server Error';
                } else {
                    err.data.meta.error_message.forEach(errorMesssage => {
                        for (let fieldName in errorMesssage) {
                            if (form[fieldName]) {
                                form[fieldName].$setValidity('mongoose', false);
                                this.errors[fieldName] = errorMesssage[fieldName];
                            }
                        }
                    })
                }
            } else {
                this.serverError = 'Internal Server Error';
                console.log(err);
            }
        }

        getSiteTurbines() {
            this.site.turbines.forEach(turbine => {
                let displayTurbine = '';
                if(turbine.name) {
                    displayTurbine+=turbine.name;
                }
                if(turbine.model) {
                    displayTurbine+= ' '+turbine.model;
                }
                if(turbine.serial) {
                    displayTurbine+= ' ('+turbine.serial+')';
                }
                turbine.displayTurbine = displayTurbine.trim();
            })

            this.site.turbines = _.sortBy(this.site.turbines, (turbine) => {
                return [turbine.displayTurbine.toLowerCase()];
            });
        }
    }

    angular.module('windmanagerApp')
        .component('reportConfig', {
            templateUrl: 'app/report-config/report-config.html',
            controller: ReportConfigComponent,
            controllerAs: 'rc'
        });

})();